import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  scrollNg(element: HTMLElement) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  scroll(id: string) {
    let element = document.getElementById(id)
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  openDemoSite() {
    window.open('https://churchwebsite-demo.web.app/home', '_blank');
  }

  openEmail() {
    window.location.href = "mailto:hello@churchzeit.com?subject=Hi Churchzeit. I have a question"
  }

}
